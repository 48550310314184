import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import routes from './routes';

function Index() {
	
	return (
		<RouterProvider router={routes} />
	);
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Index />);