import { createBrowserRouter, Navigate } from "react-router-dom";

// pages
import TranscriptPage from './components/pages/transcript';
import SiteMain from "./components/pages/siteMain";
import PrivacyPage from "./components/pages/privacy";
import TermsPage from "./components/pages/terms";
import SiteRoot from './components/pages/siteRoot';

export default createBrowserRouter([{
		path: '/',
		element: <SiteRoot />,
		children: [
			{
				index: true,
				element: <SiteMain />,
			},
			{
				path: 'share/:episodeId',
				element: <TranscriptPage />,
			},
			{
				path: 'privacy',
				element: <PrivacyPage />,
			},
			{
				path: 'terms',
				element: <TermsPage />,
			},
		]
	}]
);