//import './index.css'
import { Outlet, NavLink, useLocation } from 'react-router-dom';

export default function SiteRoot() {
	const location = useLocation();

	return (
		<div id="site-root">
			<div id="inner-frame">
				<Outlet />
			</div>
		</div>
	);
}